<template>
  <div id="ContatoCadastro">
      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

        <v-toolbar-title
          class="mt-0 mb-13 ml-4 pa-0 d-flex flex-column w-100"
          dark
          elevation="0"
        >
          <div class="d-flex align-center w-100" tabindex="-1">
           <!-- TITULO DA PAGINA -->
      <v-toolbar-title class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0">
        <router-link
        :to="'/contatoconsulta'"
        class="nav-link"
        aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
          </v-btn>
        </router-link> 
        Cadastro de Contato
      </v-toolbar-title>
      <!-- FIM TITULO DA PAGINA -->

            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn  class="text-white mt-2 mb-n2"
                    :color="COR_PRINCIPAL"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    >
                  <v-icon class="mr-1" color="light-blue accent-2"
                    >mdi-view-headline</v-icon
                  >
                  <span>Opções</span>
                </v-btn>
              </template>

              <!-- Botões do Opções -->
              <v-list class="py-0">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  @click="() => {}"
                >
                  <v-list-item-title
                    class=""
                    v-if="item.title == 'Excluir'"
                    ><v-icon color="red" class="mr-2 icon-menu"
                      >mdi-delete</v-icon
                    >
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>

            </v-menu>
          </div>
        </v-toolbar-title>

        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>
    
    <!-- Help -->
    <v-expansion-panels
      class="pa-4 pt-0 px-0 expansion-panel d-none"
      accordion>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="panel-header font-weight-bold"
          disable-icon-rotate
        >
          Documentos Básicos
          <template v-slot:actions>
            <v-icon color="" class="icon-help">
              mdi-help-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          Conteúdo teste
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Fim Help -->

    <v-row class="mt-n11 w-100 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 w-100">
        <v-container class="container-table container-rounded px-0 w-100 mx-0">

        <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
        <v-dialog
          v-if="dialog_crop"
          :value="true"
          persistent
          style="width: 330"
          :max-width="330"
          :retain-focus="false"
        >
          <v-card>
            <v-card-title>Foto</v-card-title>
            <v-card-text>
              <VueCropImage
                :img="dados.contato_foto"
                :imgsync.sync="dados.contato_foto"
                :dialog_crop.sync="dialog_crop"
                ref="VueCropImage"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- --------------------------------------------------- -->

        <!-- Conteiner Principal  -->
          <!-- Form Cadastro -->
          <v-row class="w-100 d-flex justify-center">
            <v-col cols="12" sm="6">

              <h4 class="h5 font-regular-bold">Dados Pessoais</h4>

              <v-form
                class="mt-5"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <div class="d-flex justify-center">
                  <v-avatar
                    size="100px"
                    v-ripple
                    v-if="!dados.contato_foto"
                    class="grey lighten-2 mb-6"
                  >
                    <span v-on:click="dialog_crop = true"
                      >Clique p/ adicionar avatar
                    </span>
                  </v-avatar>
                  <v-avatar size="100px" v-ripple v-else class="mb-3">
                    <img
                      :src="dados.contato_foto"
                      alt="avatar"
                      v-on:click="dialog_crop = true"
                    />
                  </v-avatar>

                </div>

                <v-text-field
                  v-model="dados.contato_nome"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Nome"
                  placeholder="Nome"
                  filled
                  autofocus
                  required
                  dense
                  :rules="nameRules"
                ></v-text-field>

                <v-text-field
                  v-model="dados.contato_cpfcnpj"
                  v-mask="MASK_CPFCNPJ"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="CPF/CNPJ"
                  placeholder="CPF/CNPJ"
                  filled
                  :rules="cpfNaoObrigatorioRules"
                  required
                  dense
                ></v-text-field>
                <!-- v-mask="`${ maskCPFCNPJ }`" -->

                <v-text-field
                  v-model="dados.contato_email"
                  class="mb-0"
                  light
                  loader-height="1"
                  hint="Por exemplo: meunome@meuservidor.com.br"
                  background-color="#FFF"
                  label="Email"
                  placeholder="Digite o seu email"
                  filled
                  maxlength="120"
                  :rules="emailNaoObrigatorioRules"
                  required
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="dados.contato_celular"
                  v-mask="MASK_TELEFONE"
                  class="mb-0"
                  background-color="#FFF"
                  label="Celular"
                  placeholder="(00)00000-0000"
                  filled
                  :rules="foneNaoObrigatorioRules"
                  required
                  dense
                ></v-text-field>
              </v-form>
              <!-- Fim Form Cadastro -->
            </v-col>
            <!-- v-mask="`${ maskTELEFONE }`" -->

          </v-row>

          <v-divider></v-divider>

          <v-card color="transparent" elevation="0" class="d-flex justify-center mt-3 mb-3">
            <router-link
              :to="{ name: 'ContatoConsulta' }"
              class="nav-link"
              aria-current="page"
            >
              <v-btn
                id="btn_cancelar"
                ref="btn_cancelar"
                class="mr-4"
                color="primary"
                text
              >
                Cancelar
              </v-btn>
            </router-link>

            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading"
              v-on:click="validate()"
            >
              Salvar
            </v-btn>
          </v-card>

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    <v-alert
      :value="alert"
      v-on:click="fecha_alert()"
      color="red"
      dark
      icon="mdi-home"
      transition="scale-transition"
      elevation="1"
      type="warning"
      dense
    >
      {{ alert_msg }}
    </v-alert>
    <!-- <Footer /> -->

  </div>
</template>

<script>
  import store_Contato from "./store_Contato";
  import store_site from "../../store/store_site";
  import {
    handleFileUpload,
    CpfCnpjIsValid,
    emailIsValid,
    telefoneIsValid,
  } from "../../services/funcoes";
  import { COR_PRINCIPAL, MASK_CPFCNPJ, MASK_TELEFONE } from "../../services/constantes";
  import { ContatoPut, ContatoPost } from "../../services/autenticacao";
  import { FuncoesGet } from "../../services/global";
  import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";
  const FileType = require("file-type");

  export default {
    name: "ContatoCadastro",

    components: {
      VueCropImage,
    },

    data() {
      return {
        isOpen: false,

        img: null,
        imgsync: null,
        dialog_crop: false,
        dialog_webcam: false,

        store_Contato: store_Contato,
        store_site: store_site,

        MASK_CPFCNPJ      : MASK_CPFCNPJ,
        MASK_TELEFONE     : MASK_TELEFONE,
        COR_PRINCIPAL     : COR_PRINCIPAL,

        /* Campos */
        acao: "E", // E=Edicao, I=Inclusão
        valid: true,
        alert: false,
        alert_msg: "",
        loading : false,

        stencilCoordinates: { width: 200, height: 200 },

        dados   : { cod_contato: null,
                    cod_usuario: null,
                    contato_nome: "",
                    contato_cpfcnpj: "",
                    contato_celular: "",
                    contato_rg: "",
                    contato_rg_orgao: "",
                    contato_status: null,
                    contato_nasc_dt: null,
                    contato_cadastro_dt: null,
                    contato_email: "",
                    contato_favorito: "N",
                    contato_profissao: "",
                    contato_estado_civil: "",
                    contato_telefone: "",
                    contato_foto: null,
                  },

        /* Menu Opções (Button Dots) */
        items: [
          // {
          //   title: "Editar",
          // },
          {
            title: "Excluir",
          },
        ],

        /* Formulário */
        form: false,
        nameRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
        ],
        emailRules: [
          (value) => !!value || "O e-mail é obrigatório",
          (value) => (value && emailIsValid(value)) || "O e-mail não é válido",
        ],
        emailNaoObrigatorioRules: [
          // (value) => !!value || "O e-mail é obrigatório",
          (value) => ((!value || value.length <= 0) || (emailIsValid(value))) || "O e-mail não é válido",
        ],
        foneRules: [
          (value) => !!value || "O celular é obrigatório",
          (value) => (value && telefoneIsValid(value)) || "O celular não é válido",
        ],
        foneNaoObrigatorioRules: [
          // (value) => !!value || "O celular é obrigatório",
          (value) => ((!value || value.length <= 0) || telefoneIsValid(value)) || "O celular não é válido",
        ],
        cpfRules: [
          (value) => !!value || "O CPF é obrigatório",
          (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
        ],
        cpfNaoObrigatorioRules: [
          (value) => ((!value || value.length <= 0) || (value && CpfCnpjIsValid(value))) || "O CPF não é válido",      
        ],
      };
    },

    watch: {
      // dialog_crop() {
      //   console.log('MUDOU');
      // },
      // imgSrc() {
      //   console.log('MUDOU ImgScr');
      // }
    },

    async mounted() {
      //console.log(process.env)

      var lo_params = { cod_contato: this.$route.params.cod_contato };

      // Edição do cadastro -----------------------------------------
      if (lo_params.cod_contato) {
        var lo_Res = await this.store_Contato.ContatoGet(lo_params);
        if (lo_Res)
          this.dados = { ...lo_Res };
        this.acao = "E";
        //console.log(this.dados.foto)
      }
      // Inclusão de novo Contato -----------------------------------
      else {
        this.acao = "I";
      }
    },

    methods: {
      startCameraStream() {
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        });
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.$refs.camera.srcObject = stream;
          })
          .catch((error) => {
            console.log(
              "Browser doesn't support or there is some errors." + error
            );
          });
      },

      async getDevices() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          //console.log("NAO")
          return false;
        }
        try {
          let allDevices = await navigator.mediaDevices.enumerateDevices();
          //console.log("SIM", allDevices)
          for (let mediaDevice of allDevices) {
            if (mediaDevice.kind === "videoinput") {
              let option = {};
              option.text = mediaDevice.label;
              option.value = mediaDevice.deviceId;
              this.options.push(option);
              this.devices.push(mediaDevice);
            }
          }
          return true;
        } catch (err) {
          throw err;
        }
      },

      // --------------------------------------------------------------------------
      abre() {
        if (!this.dados.contato_foto) {
          this.$refs.file_new.click();
        } else {
          this.$refs.file_update.click();
        }
      },

      fecha_alert() {
        this.alert = false;
      },

      /* Método que recebe arquivo de imagem */
      handleFile() {
        this.dialog_crop = true;
      },

      async validate() {
        const lb_valido = this.$refs.form.validate();
        if (lb_valido) {
          this.loading = true
          var lo_JSON = { ...this.dados };

          // Editando Contato ----------------
          var ls_form;
          if (this.acao == "E") {
            ls_form = await this.store_Contato.ContatoPut(lo_JSON);
            // Incluindo Contato ----------------
          } else {
            ls_form = await this.store_Contato.ContatoPost(lo_JSON);
          }

          if (ls_form.result.message == "success") {
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_form.result.result.trim();
            this.store_site.alert           = true;


          } else {
            this.store_site.alert_cor       = "#A00000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_form.errors.trim();
            this.store_site.alert           = true;
          }
          this.loading = false
        }
      },
      reset() {
        this.$refs.form.reset();
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      },
    },
  };
</script>

<style scoped>
  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .container-rounded {
    max-width: 100%;
    background: #F2F6F7 !important;
    border-radius: 12px !important;
    margin: auto !important;
    margin-bottom: 20px !important;
    padding: 20px 13px 13px 13px !important;
  }

  /* ======================== PRIVILEGES ===================== */
  .h5 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .h5::after {
    display: block;
    width: 50px;
    height: 3px;
    margin-top: 0.2rem;
    content: "";
    background: #274abb;
  }

  .btn {
    width: 120px;
  }

  .v-btn {
    display: inline-block;
  }

  /* CELULAR */
  @media (max-width: 599px) {

    .container {
      margin: 0px !important;
      padding: 0px 8px !important;
    }

    .container-rounded {
      background: #F2F6F7 !important;
      border-radius: 12px !important;
      max-width: calc(100%) !important;
      margin: auto !important;
      padding: 20px 13px 13px 13px !important;
    }

    .title-page {
      margin-left: 16px !important;
    }

    .theme--light .v-data-table {
      border-radius: 12px 12px 0px 0px !important;
    }

    .avatar {
      margin: 0px 0px 0px 4px !important;
    }
  }


</style>